class RequestError extends Error {
  validation = {};

  constructor({ message, response, cancel, ...rest }) {
    super(message || 'An unknown error occurred');

    this.response = response || null;
    this.cancel = cancel || null;

    Object.keys(rest).forEach((key) => {
      this[key] = rest[key];
    });

    if (response && response.data) {
      if (response.data.validation) {
        this.validation = response.data.validation;
      }
      if (response.data.error && response.data.error.message) {
        this.message = response.data.error.message;
      }
    }
  }

  get generalError() {
    return this.message || 'An unknown error occurred';
  }

  get validationErrors() {
    return this.validation || {};
  }

  get cancelError() {
    return this.cancel || null;
  }

  get responseError() {
    return this.response || null;
  }

  get exceptionMessageError() {
    return this.response?.data?.exception?.message || this.generalError;
  }
}

export default RequestError;

import React from 'react';
import termsConfig from 'components/modals/TermsModal/termsConfig';
import UnderCut from 'components/modals/TermsModal/UnderCut';
import classes from 'components/modals/TermsModal/TermsModal.module.scss';
import clsx from 'clsx';
import styles from './styles.module.scss';

const getTextWithLinks = (underCut) => {
  const { text, linkLabel1, linkLabel2, linkHref1, linkHref2 } = underCut;
  const parts = text.split(linkLabel1);
  const firstPart = parts[0];
  const secondParts = parts[1].split(linkLabel2);
  const secondPart = secondParts[0];
  const thirdPart = secondParts[1];
  return (
    <>
      {firstPart}
      <a href={linkHref1} className="primary-color">
        {linkLabel1}
      </a>
      {secondPart}
      <a href={linkHref2} className="primary-color">
        {linkLabel2}
      </a>
      {thirdPart}
    </>
  );
};
const Terms = () => (
  <div className="container py-5">
    <h1 className={clsx('text-lg text-uppercase font-title text-center mb-2', styles.title)}>
      Pin The Look Terms
    </h1>
    <h2 className={clsx('text-lg font-title text-center mb-4', styles.subTitle)}>
      Learn about the Pin the Look platform terms of use, content, payments, and liability for a
      productive experience.
    </h2>
    <ol className={classes.termsList}>
      {termsConfig.map(({ title, description, underCut }) => (
        <li key={title}>
          <div className="d-flex mb-3">
            <span className={classes.rootTitle}>{title}</span>
            <div className="text-sm">
              <p className="mb-1">{description.text}</p>
              {description.list && (
                <ul className="mb-1" style={{ listStyleType: 'none' }}>
                  {description.list.map((listItem) => (
                    <li className="mb-2" key={listItem.id}>
                      <span style={{ marginRight: '0.5em', fontSize: '20px' }}>&#8226;</span>
                      {listItem.text && <span className="mb-1">{listItem.text}</span>}
                    </li>
                  ))}
                </ul>
              )}
              {!!underCut && (
                <UnderCut>
                  {underCut.text && (
                    <p className="mb-1">
                      {underCut.split ? getTextWithLinks(underCut) : underCut.text}
                    </p>
                  )}
                  {underCut.list && (
                    <ol type="a" className={classes.underCutList}>
                      {underCut.list.map(
                        ({ title: listTitle, sublist, id, sublistType, text: listText }) => (
                          <li className="mb-1" key={id}>
                            <p className={`${classes.listTitle} mb-1`}>{listTitle}</p>
                            <p className="mb-1">{listText}</p>
                            <ol type={sublistType ?? null}>
                              {sublist.map(({ title: sublistTitle, text }) => (
                                <li className="mb-1" key={sublistTitle}>
                                  <p>{text}</p>
                                </li>
                              ))}
                            </ol>
                          </li>
                        ),
                      )}
                    </ol>
                  )}
                </UnderCut>
              )}
            </div>
          </div>
        </li>
      ))}
    </ol>
  </div>
);

export default Terms;

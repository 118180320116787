import {
  GTL_LIKE_LOOKBOARD,
  GTL_RESET_FILTER_VALUES,
  GTL_SET_ACTIVE_TAB,
  GTL_SET_IMAGES,
  GTL_SET_LIKED_IMAGES,
  GTL_SET_LIKED_LOOK_BOARDS,
  GTL_SET_SELECTED_IMAGE,
  GTL_UPDATE_FILTER_VALUES,
  GTL_UPDATE_HAS_MORE_STATUS,
  GTL_UPDATE_IMAGES,
  GTL_UPDATE_LOADING_STATUS,
  GTL_UPDATE_LOOK_BOARDS_DATA,
  GTL_UPDATE_ALL_LOOK_BOARDS_DATA,
  GTL_UPDATE_MIX_MATCH_SORT,
  GTL_UPDATE_MIX_MATCH_SUBTYPE,
  GTL_UPDATE_MIX_MATCH_TYPE,
  GTL_UPDATE_SEARCH_PARAMS,
  GTL_VOTE_LOOKBOARD,
  GTL_UPDATE_PRODUCT_SORT,
  GTL_SET_PRODUCT_VIEW,
  GTL_UPDATE_PRODUCT_FILTER_VALUES,
  GTL_UPDATE_PRODUCT_SEARCH_PARAMS,
  GTL_RESET_PRODUCT_SEARCH_PARAMS,
  GTL_CLEAR_PRODUCT_FILTER_VALUES,
} from 'modules/getTheLook/store/constants';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import productService from 'modules/product/productService';
import { setDefaultFilters } from 'modules/auth/store/actions';
import {
  imagePageTypes,
  imageSortKeys,
  imageSourceKeys,
} from 'constants/inspirationImageSearchParams';
import { unauthorizedLimit } from 'constants/inspirationImages';
import { routesByName } from 'constants/routes';
import { lookBoardSortKeys, lookBoardSourceKeys } from 'constants/lookBoardSearchParams';
import { initialFilterValues } from './reducer';
import { getTheLookTabKeys } from '../constants';
import { initialProductFilterValues } from '../../curateTheLook/store/reducer';
import errorToastr from '../../../libs/toastr/errorToastr';

export const changeActiveTabAction = (tab) => (dispatch) => {
  dispatch({ type: GTL_SET_ACTIVE_TAB, payload: tab });
};

export const updateSearchParamsAction = (params, likedSort) => (dispatch) => {
  dispatch({
    type: GTL_UPDATE_SEARCH_PARAMS,
    payload: {
      ...params,
      sort: likedSort ? imageSortKeys.liked : imageSortKeys.highest,
    },
  });
};

export const updateLookBoardsDataAction = (images) => async (dispatch) => {
  const imageIds = images.filter((id) => !!id);
  const promiseArr = imageIds.map((imageId) => {
    if (imageId) return inspirationImageService.getLookBoardDetails(imageId);
    return null;
  });

  const result = await Promise.all(promiseArr);
  const lookBoardsData = imageIds.reduce(
    (accum, item, index) => ({ ...accum, [item]: result[index].result }),
    {},
  );

  dispatch({
    type: GTL_UPDATE_LOOK_BOARDS_DATA,
    payload: lookBoardsData,
  });
  dispatch({
    type: GTL_UPDATE_ALL_LOOK_BOARDS_DATA,
    payload: lookBoardsData,
  });
};

export const loadFirstAction = (createCancelToken, count = 10) => async (dispatch, getState) => {
  const {
    app: { enums },
    getTheLook: { searchParams, filterValues, activeTab },
    auth: { user },
    router: { location },
  } = getState();

  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: true });

  const cancelToken = createCancelToken();
  const colors = Object.values(enums.colors)
    .filter((color) => filterValues.colors.includes(color.color_group_id))
    .map((color) => color.id);

  let viewType = null;
  if (activeTab === getTheLookTabKeys.lookBoardView) {
    viewType = 'lookBoard';
  }
  if (activeTab === getTheLookTabKeys.productStream) {
    viewType = 'product';
  }

  const promiseImages = inspirationImageService.getImagesWithLookBoard(
    {
      ...searchParams,
      ...(searchParams.search.trim().length ? initialFilterValues : filterValues),
      ...(searchParams.search.trim().length ? {} : { colors }),
      requestCount: count,
      viewType,
    },
    { cancelToken },
  );
  const [transformedList, list] = await promiseImages;

  if (user) {
    const promiseLikedImages = inspirationImageService.getImages(
      {
        source: imageSourceKeys.liked,
        subStyles: [],
        offset: 0,
        sort: imageSortKeys.newest,
        pageType: imagePageTypes.getTheLook,
      },
      { cancelToken },
    );
    if (activeTab !== getTheLookTabKeys.mixAndMatch) {
      try {
        const promiseLikedLookBoards = lookBoardService.getLookBoards(
          {
            draft: 0,
            source: lookBoardSourceKeys.liked,
            sort: lookBoardSortKeys.newest,
            offset: 0,
          },
          { cancelToken },
        );

        const { result } = await promiseLikedLookBoards;
        dispatch({ type: GTL_SET_LIKED_LOOK_BOARDS, payload: result });
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    }
    const [transformedImageLikedList, imageLikedList] = await promiseLikedImages;

    dispatch({ type: GTL_SET_LIKED_IMAGES, payload: imageLikedList });

    if (searchParams.source === imageSourceKeys.liked) {
      const imageIds = Object.values(imageLikedList).map(({ id }) => `${id}`);
      dispatch({ type: GTL_SET_IMAGES, payload: imageIds });
      dispatch(updateIILibraryAction(transformedImageLikedList));
    }
  }

  const imageIds = Object.values(list).map(({ id }) => `${id}`);

  if (searchParams.source !== imageSourceKeys.liked) {
    dispatch(updateIILibraryAction(transformedList));
    dispatch({ type: GTL_SET_IMAGES, payload: imageIds });
  }

  if (location.pathname === routesByName.getTheLook.lookBoardView.index) {
    await dispatch(updateLookBoardsDataAction(imageIds));
  }

  if (imageIds.length > 0) {
    dispatch(
      updateSearchParamsAction({
        offset: searchParams.offset + imageIds.length,
      }),
    );
  }

  dispatch({
    type: GTL_UPDATE_HAS_MORE_STATUS,
    payload: imageIds.length === count,
  });
  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: false });
};

export const loadMoreAction = (count = 10) => async (dispatch, getState) => {
  const {
    app: { enums },
    auth: { user },
    getTheLook: { searchParams, filterValues, activeTab },
    router: { location },
  } = getState();

  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: true });

  const colors = Object.values(enums.colors)
    .filter((color) => filterValues.colors.includes(color.color_group_id))
    .map((color) => color.id);

  let viewType = null;
  if (activeTab === getTheLookTabKeys.lookBoardView) {
    viewType = 'lookBoard';
  }
  if (activeTab === getTheLookTabKeys.productStream) {
    viewType = 'product';
  }
  const [transformedList, list] = await inspirationImageService.getImagesWithLookBoard({
    ...searchParams,
    ...(searchParams.search.trim().length ? initialFilterValues : filterValues),
    ...(searchParams.search.trim().length ? {} : { colors }),
    requestCount: count,
    pageType: imagePageTypes.getTheLook,
    viewType,
  });

  const imageIds = Object.values(list).map(({ id }) => `${id}`);
  if (location.pathname === routesByName.getTheLook.lookBoardView.index) {
    await dispatch(updateLookBoardsDataAction(imageIds));
  }
  dispatch(updateIILibraryAction(transformedList));
  dispatch({ type: GTL_UPDATE_IMAGES, payload: imageIds });

  const updatedListLength = searchParams.offset + imageIds.length;

  if (imageIds.length > 0) {
    dispatch(
      updateSearchParamsAction({
        offset: updatedListLength,
      }),
    );
  }

  const hasMore =
    !user && updatedListLength >= unauthorizedLimit ? false : imageIds.length === count;

  dispatch({
    type: GTL_UPDATE_HAS_MORE_STATUS,
    payload: hasMore,
  });
  dispatch({ type: GTL_UPDATE_LOADING_STATUS, payload: false });
};

export const toggleLikeLookBoardAction = (imageId, lookBoardId, isLiked) => async (dispatch) => {
  await lookBoardService.toggleLike(lookBoardId, isLiked);
  dispatch({
    type: GTL_LIKE_LOOKBOARD,
    payload: { imageId, lookBoardId, isLiked },
  });
};

export const voteLookBoardAction = (imageId, lookBoardId, voteValue) => async (dispatch) => {
  await lookBoardService.voteHandler(lookBoardId, voteValue);

  dispatch({
    type: GTL_VOTE_LOOKBOARD,
    payload: { imageId, lookBoardId, voteValue },
  });
};

export const toggleLikeProductAction = (imageId, productId, isLiked) => async (
  dispatch,
  getState,
) => {
  const {
    getTheLook: { lookBoardsData },
  } = getState();

  await productService.toggleLike(productId, isLiked);

  const currentImageCopy = { ...lookBoardsData[imageId] };
  const currentProduct = currentImageCopy.products.find(({ id }) => id === productId);
  currentProduct.isLiked = isLiked;

  dispatch({
    type: GTL_UPDATE_LOOK_BOARDS_DATA,
    payload: { [currentImageCopy.id]: currentImageCopy },
  });
};

export const updateSelectedImageAction = (image) => (dispatch) => {
  dispatch({ type: GTL_SET_SELECTED_IMAGE, payload: image });
};

export const updateMixMatchTypeAction = (type) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_MIX_MATCH_TYPE, payload: type });
};

export const updateMixMatchSubtypeAction = (type) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_MIX_MATCH_SUBTYPE, payload: type });
};

export const updateMixMatchSortAction = (type) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_MIX_MATCH_SORT, payload: type });
};

export const updateProductSortAction = (type) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_PRODUCT_SORT, payload: type });
};

export const syncInspirationImageAction = (imageId) => async (dispatch) => {
  const { result: image } = await inspirationImageService.getImageById(imageId);
  if (image?.message === 'Resource has been moved.') {
    window.location.href = image.redirect_to;
  } else {
    dispatch(updateIILibraryAction({ [image.id]: image }));
    dispatch(updateSelectedImageAction(image));

    const { result: imageDetails } = await inspirationImageService.getLookBoardDetails(imageId);
    dispatch({
      type: GTL_UPDATE_LOOK_BOARDS_DATA,
      payload: { [imageId]: imageDetails },
    });
  }
};

export const updateFilterValuesAction = (filterValues) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_FILTER_VALUES, payload: filterValues });
  dispatch(updateSearchParamsAction({ offset: 0 }));
};
export const clearFilterValuesAction = () => (dispatch) => {
  dispatch({ type: GTL_RESET_FILTER_VALUES });
};

export const updateGTLProductSearchParamsAction = (payload) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_PRODUCT_SEARCH_PARAMS, payload });
};

export const resetGTLProductSearchParamsAction = () => (dispatch) => {
  dispatch({ type: GTL_RESET_PRODUCT_SEARCH_PARAMS });
};

export const updateGTLProductFilters = (filterValues) => (dispatch) => {
  dispatch({ type: GTL_UPDATE_PRODUCT_FILTER_VALUES, payload: filterValues });
  dispatch(updateGTLProductSearchParamsAction({ offset: 0 }));
};
export const clearGTLProductFilters = () => (dispatch) => {
  dispatch({ type: GTL_CLEAR_PRODUCT_FILTER_VALUES });
  dispatch(resetGTLProductSearchParamsAction());
};

export const resetGTLProductFilters = () => async (dispatch, getState) => {
  const {
    auth: { user },
  } = getState();
  if (user?.preferences) {
    const { preferences } = user;
    const filters = {
      ...initialProductFilterValues,
      styles: preferences.styles ?? [],
      subStyles: preferences.subStyles ?? {},
      roomTypes: preferences.roomTypes ?? [],
      colors: Object.values(preferences.colors) ?? [],
    };
    dispatch(updateGTLProductFilters(filters));
    dispatch(resetGTLProductSearchParamsAction());
  } else {
    dispatch(clearGTLProductFilters());
  }
};

export const resetFilterValuesAction = () => async (dispatch, getState) => {
  const {
    auth: { user },
  } = getState();
  if (user?.preferences) {
    const { preferences } = user;
    const filters = {
      styles: preferences.styles ?? [],
      subStyles: preferences.subStyles ?? {},
      roomTypes: preferences.roomTypes ?? [],
      colors: Object.values(preferences.colors) ?? [],
    };
    dispatch(updateFilterValuesAction(filters));
  } else {
    await setDefaultFilters(dispatch, getState);
  }
};

export const clearAllFilterValuesAction = (filterStyle) => (dispatch) => {
  if (filterStyle) {
    dispatch(updateSearchParamsAction({ offset: 0 }, filterStyle));
  } else {
    dispatch(updateSearchParamsAction({ offset: 0 }));
  }
  dispatch(clearFilterValuesAction());
};

export const setLikedImagesAction = (likedImages) => (dispatch) => {
  dispatch({ type: GTL_SET_LIKED_IMAGES, payload: likedImages });
};
export const changeProductViewAction = (view) => (dispatch) => {
  dispatch({ type: GTL_SET_PRODUCT_VIEW, payload: view });
};

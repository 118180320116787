export const localStorageKeys = {
  appVersion: 'appVersion',
  applicationConfig: 'applicationConfig',
  checksum: 'checksum',
  enums: 'enums',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  ownColorBanners: 'ownColorBanners',
  ownImageBanners: 'ownImageBanners',
  verifyModalWasShown: 'verifyModalWasShown',
  disableVerifyLinkTime: 'disableVerifyLinkTime',
  watchedRecommendations: 'watchedRecommendations',
  quizResult: 'quizResult',
  introModalsStatus: 'IntroModalsStatus',
  lookBoardData: 'CTL_lookBoardData',
  lookBoardDetailsFormValues: 'CTL_formValues',
  editState: 'CTL_editState',
  requestInfo: 'CTL_requestInfo',
  quizViewed: 'GTL_quizViewed',
  newsletterSent: 'newsletterSent',
  personaSent: 'personaSent',
};

export const userStatus = {
  complete: 'complete',
  noPref: 'no_pref',
  preComplete: 'pre_complete',
  invited: 'invited',
  unconfirmed: 'unconfirmed',
};

export default {
  localStorageKeys,
  userStatus,
};
